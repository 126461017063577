/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */
@use '@angular/material' as mat;
@import './theme-variables';
@import './app-pallete';


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$white-primary: mat.define-palette($app-gray, A100);
$white-secondary: mat.define-palette($app-red, 500);
$white-warn: mat.define-palette($app-red, 500);

$g-app-primary: mat.define-palette($app-gray, 900);
$g-app-secondary: mat.define-palette($app-gray, 700);
$g-app-warn: mat.define-palette($app-red, 500);

// The warn palette is optional (defaults to red).

// Create the theme object (a Sass map containing all of the palettes).

$white-theme: mat.define-light-theme($white-primary, $white-secondary, $white-warn);
$g-app-theme: mat.define-light-theme($g-app-primary, $g-app-secondary, $g-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.


@include mat.all-component-themes($g-app-theme);

.white-theme {
  @include mat.all-component-themes($white-theme);
}
