@import "~src/theme/theme-variables";

.quantity-input {
  font-weight: 400;
  flex: 1;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: $white;
  color: $gray-700;
  border-radius: 2px;
  font-size: 15px;
  line-height: 18px;
  padding: 5px;
  border: 2px solid $gray-700;

  .mat-form-field-wrapper {
    padding-bottom: 0;
    height: 100%;

    .mat-form-field-flex {
      height: 100%;
      align-items: center;
    }

    .mat-form-field-infix {
      width: unset;
      padding: 0;
      border-top: unset;

      .mat-input-element {
        color: $gray-700;
        outline: none;
        caret-color: $gray-700;
        text-align: right;

        &[type=number]::-webkit-outer-spin-button,
        &[type=number]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      .mat-form-field-label-wrapper {
        display: none;
      }
    }

    .mat-form-field-suffix {
      vertical-align: bottom;
      font-weight: 300;
      align-self: center;
      justify-self: flex-end;
      margin-left: 2px;

      .unit {
        line-height: 1;
        font-size: 10px;
        display: inline-block;
        padding: 0 1px 0 1px;
      }
    }


    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-subscript-wrapper {
      display: none;
    }
  }
}

.overload {
  .quantity-input {
    border: 3px solid $red;
    color: $red;

    .mat-form-field-infix {
      .mat-input-element {
        color: $red;
        caret-color: $red;
      }
    }
  }
}
