@import "~src/theme/theme-variables";

mat-form-field.min-space {
  .mat-form-field-infix {
    border-top: none;
    padding-top: 0;
    padding-bottom: 0.2em;
  }

  &.pb-redefine {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      top: 1.25em;
      bottom: unset;
    }
  }
}

mat-form-field.wide {
  width: 100%;
}

mat-radio-group.radio-list {
  .mat-radio-button {
    margin-bottom: 15px;
    display: block;

    &:first-of-type {
      margin-top: 15px;
    }

    &.mat-radio-checked {
      .mat-radio-outer-circle {
        border-color: $red;
      }

      .mat-radio-inner-circle {
        background-color: $red;
      }
    }
  }
}
