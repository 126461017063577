/*
 * File: newStyles.scss
 * Project: galilea-centro
 * File Created: Thursday, 29th April 2021 10:26:24 am
 * Author: Greg Gómez (greg@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

@import '~src/theme/theme-variables';

.justify-align {
  display: flex;
  align-items: center;
}

.center-align {
  text-align: center !important;

  &.justify {
    justify-content: center;
  }
}
.right-align {
  text-align: right !important;

  &.justify {
    justify-content: flex-end;
  }
}
.left-align {
  text-align: left !important;

  &.justify {
    justify-content: flex-start;
  }
}

.base-color {
  color: $charcoal-grey;
}

.yellow-color {
  color: #ed9935;
}

.red-color {
  color: #c01314;
}
.green-color {
  color: green;
}
