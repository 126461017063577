@import '~src/assets/mixins/form.mixins';
@import "~src/theme/theme-variables";

button.btn-standalone {
  @include btn-continue(45px, 120px, 0.9rem, 10px, 10px, $red);
}

button.btn-primary {
  @include btn-continue(45px, 0,0.85rem , 10px, 10px, $red);
}

button.btn-secondary {
  @include btn-continue(45px, 0, 0.85rem, 0px, 10px, $white, $red, none);
}

button.white-theme {
  &.mat-raised-button,
  &.mat-button {
    &[disabled],
    &[disabled="true"] {
      background-color: $gray-200;
      opacity: 1;
      color: $gray-100;
    }
  }
}
