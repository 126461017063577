@import "~src/theme/theme-variables";

mat-checkbox {
  .mat-checkbox-inner-container {
    height: 22px;
    width: 22px;
  }

  .mat-checkbox-frame {
    border-color: $gray-700;
    border-radius: 0;
    border-width: 2px;
  }

  // &.mat-checkbox-disabled {
  //   opacity: 0.4;

  //   &.exclude-opacity {
  //     opacity: 1;
  //   }

  //   .mat-checkbox-frame {
  //     border-color: $gray-900;
  //   }

  //   .mat-checkbox-background {
  //     svg {
  //       display: none;
  //     }

  //     .mat-checkbox-mixedmark {
  //       display: none;
  //     }

  //     &::before {
  //       position: absolute;
  //       content: '';
  //       z-index: 1;
  //       border: 1px solid $white;
  //       left: 20%;
  //       right: 20%;
  //     }

  //     &::after {
  //       content: '';
  //       position: absolute;
  //       top: 0;
  //       bottom: 0;
  //       left: 0;
  //       right: 0;
  //       z-index: 0;
  //       background: $gray-900;
  //     }
  //   }
  // }
}
