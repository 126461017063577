@import '~src/theme/theme-variables';

// defaults to login view
@mixin _form-centered(
  $gv-padding: 0,
  $gh-padding: 15%,
  $mff-width: 100%,
  $btn-width: 100%,
  $mi-border-width: 2px,
  $theme-color: $white
) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  padding: $gv-padding $gh-padding;

  mat-form-field {
    font-family: Roboto;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;

    &.mat-focused {
      .mat-form-field-label {
        color: $theme-color;
      }
    }

    .mat-form-field-label {
      font-family: Roboto;
      font-size: 15px;
      line-height: 18px;
      font-weight: 500;
      color: $white;
    }

    .mat-form-field-prefix {
      margin-right: 10px;
    }

    .material-icons {
      color: $theme-color;
      vertical-align: middle;
      font-size: 18px;
    }

    .mat-input-element {
      color: $theme-color;
      caret-color: $theme-color;
    }

    width: $mff-width;
  }

  button {
    width: $btn-width;
  }

  button[type=submit] {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    font-size: 15px;
    color: $red;
    padding: 6px 20px;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
  }

  &.no-lines {
    mat-form-field {
      .mat-form-field-underline {
        display: none;
      }
    }
  }

  &.inputs-underlined {
    mat-form-field {
      .mat-form-field-infix {
        border-bottom-width: $mi-border-width;
        border-color: $theme-color;
        border-style: solid;
        border-top: none;
        border-left: none;
        border-right: none;
        padding-bottom: 3.5px;
      }
    }
  }
}
