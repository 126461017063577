/* You can add global styles to this file, and also import other style files */
@import 'theme/theme-variables';
@import 'assets/css/material-mat-checkbox';
@import 'assets/css/footer-btn-container';
@import 'assets/css/quantity-input';
@import 'assets/css/form-centered';
@import 'assets/css/mat-form-field';
@import 'assets/css/mat-spinners';
@import 'assets/css/mat-icon';
@import 'assets/css/mat-btn';
@import 'assets/css/mat-select';
@import 'assets/css/warehouse-table';
@import './newStyles.scss';

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  overscroll-behavior: none;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

a {
  text-decoration: none;
}

.capitalize {
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

.content {
  padding: 68px 0 80px;
  width: 100%;
}

.cdk-overlay-pane {
  &.no-padding {
    .mat-dialog-container {
      padding: 0;
    }
  }
  .mat-dialog-container {
    padding: 20px;
    .mat-dialog-content {
      margin: 0 -20px;
      padding: 0 20px;
    }
  }

  min-width: 280px;

  .mat-select-panel {
    //If max-height is set the mat-select container get renders in wrong place
    //max-height: 50vh;
    max-width: max-content;
  }
}

// class to reset autofocus on modals
.af-override {
  width: 0;
  height: 0;
  opacity: 0;
  outline: 0;
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  display: none;
}

.hidden {
  visibility: hidden;
}

.contractor-filter {
  // set full height, decreasing it based on header height
  height: calc(100% - 68px);

  .mat-tab-header {
    display: flex;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    background-color: $white;
    color: $gray-700;
    position: sticky;
    top: 68px;
    background-color: white;
    z-index: 4;
  }

  .mat-tab-label {
    width: 50%;
  }

  .mat-ink-bar {
    width: 50%;
    height: 6px;
  }

  .mat-tab-header {
    border: none;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.4);
  }
}

.letter {
  border-bottom: 13px solid rgba(164, 164, 164, 0.4);

  .header {
    padding: 4px 0 4px 20px;
    font-size: 20px;
    font-weight: 900;
    color: $gray-300;
    border-bottom: 1px solid $gray-light;
  }
}

.modal-ticket-warehouse {
  .mat-dialog-container {
    margin: 0;
    padding: 0;

    .mat-dialog-content {
      margin: 0;
      padding: 0;
    }
  }
}

.info-dialog {
  width: 80vw;

  .mat-dialog-title {
    margin: -24px -24px 10px;
    padding: 12px 24px;
    font-size: 18px;
    background-color: $gray-500;
    font-weight: 500;
    color: $white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    i {
      font-size: 28px;
    }
  }

  p {
    font-size: 1em;
    font-weight: 400;
    color: $gray-900;
    margin: 0 0 20px;
  }

  .options {
    display: flex;
    padding-bottom: 30px;

    button {
      font-size: 13px;
      padding: 5px 4px;
      background-color: $red-2;
      color: $white;
      border: none;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.5);
      border-radius: 2px;
      text-align: center;
      font-weight: 500;
      flex: 1;
      font-family: 'Roboto';

      &:focus {
        outline: none;
      }
    }
  }
}

.deliveries,
.ingress,
.loans {
  .mat-tab-label {
    justify-content: flex-start;
    height: 60px;
    //width: 260px
  }

  .mat-ink-bar {
    height: 6px;
  }

  .mat-header-cell {
    font-size: 13px;
    font-weight: bold;
    color: rgba(66, 73, 81, 0.6);
  }

  .mat-cell,
  .mat-footer-cell {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #424951;
  }

  .mat-footer-row,
  .mat-footer-cell,
  .mat-footer-cell:last-of-type,
  .mat-footer-cell:last-of-type {
    border-bottom: none;
    padding: 0px 15px;
  }
}

.deliveries {
  .mat-tab-label {
    //width: 228px;
  }
}

.search-bar {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-infix {
    width: 100%;
    padding: 0;
    border: none;
  }

  .mat-form-field-suffix {
    position: unset;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-label {
    padding-left: 40px;
    padding-top: 3px;
  }
}

.ingress,
.loans {
  .mat-tab-label .mat-tab-label-content {
    justify-content: space-between;
    display: flex;
    width: 260px;
  }

  mat-ink-bar.mat-ink-bar {
    width: 260px !important;
  }
}

// @media screen and(min-width: 1024px) {
//   body {
//     background-color: #b51c1d;

//     main,
//     .cdk-overlay-container {
//       height: 625px;
//       width: 355px;
//       overflow: scroll;
//       background-color: #fff;
//       margin-top: calc((100vh - 625px) / 2);
//       position: fixed;
//       margin-left: calc((100vw - 355px) / 2);

//       .construction {
//         height: calc(625px - 145px)!important;
//       }

//       .header,
//       .footer {
//         max-width: 355px;
//         position: absolute !important;
//       }

//       .content {
//         padding: 68px 0 0;
//       }
//       .footer-btn-container {
//         margin-bottom: calc((100vh - 625px) / 2);
//         max-width: 355px;
//       }
//     }

//     .cdk-overlay-container.cdk-overlay-backdrop {
//       height: 625px;
//       width: 355px;
//       overflow: scroll;
//       background-color: #fff;
//       margin-top: calc((100vh - 625px) / 2);
//       z-index: 2000;
//       margin-left: calc((100vw - 355px) / 2);
//     }
//     .cdk-overlay-pane {
//       z-index: 3000;
//       position: fixed;
//     }
//   }
// }

.mat-simple-snackbar-action {
  color: $white;
}

.mat-select-search-panel {
  /* allow absolute positioning relative to outer options container */
  transform: none !important;
  max-height: 350px;
}

.mat-select-disabled .mat-select-value {
  color: #424951;
}

.cdk-overlay-pane-select-search {
  /* correct offsetY so that the selected option is at the position of the select box when opening */
  margin-top: -50px;
}

.table-header-btn {
  height: 30px;
  line-height: 28px;
  border-radius: 4px;
  border: solid 1px #424951;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  align-items: center;
  justify-content: space-between;
  outline: none;
  cursor: pointer;
  background-color: #fff;
  position: absolute;
  right: 26px;
  top: 15px;
  z-index: 5;

  mat-icon {
    height: 18px;
    width: 18px;
    font-size: 18px;
    margin-bottom: 2px;
    margin-left: 5px;
  }
}

.modal-body {
  mat-card:not(.materials-card) {
    .mat-form-field-infix {
      padding-top: 0;
      border-top: none;
    }
  }
}

.mat-select-disabled .mat-select-trigger {
  color: #8e9092;
  opacity: 0.7;
}

.materials-form {
  .add-block {
    .mat-mini-fab .mat-button-wrapper {
      padding: 0 0 1px 1px;
      line-height: 18px;
    }
  }
}

mat-tab-group,
.mat-tab-body-wrapper,
cdk-virtual-scroll-viewport {
  height: 100%;
}

.table-center-header,
.th-center {
  .mat-sort-header-container {
    justify-content: center;
  }
}
.flatten-form {
  .mat-form-field-infix {
    margin: 0;
    border: 0;
  }
}
.full-width-form {
  mat-form-field.mat-form-field.mat-primary.mat-form-field-type-mat-select.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-hide-placeholder.ng-untouched.ng-pristine.ng-star-inserted.mat-form-field-should-float {
    width: 100%;
  }
  mat-form-field.mat-form-field.mat-primary.mat-form-field-type-mat-select.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-hide-placeholder.ng-pristine.ng-star-inserted.ng-valid.mat-form-field-should-float.ng-touched {
    width: 100%;
  }
  mat-form-field.mat-form-field.mat-primary.mat-form-field-type-mat-select.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-hide-placeholder.ng-valid.ng-star-inserted.mat-form-field-should-float.ng-touched.ng-dirty {
    width: 100%;
  }
}
.no-box-shadow {
  box-shadow: unset !important;
}

.modal-warehouse {
  .mat-form-field-infix {
    border-top: none;
  }
}

.snack-bar-container-colored {
  background-color: #424951;
  animation: rainbow 10s ease infinite;
  background-size: 1800% 1800%;
}
@keyframes rainbow {
  45% {
    background-color: #424951;
  }
  50% {
    background-color: #c01314;
  }
  55% {
    background-color: #c01314;
  }
  60% {
    background-color: #424951;
  }
}
.snackbar-success {
  background-color: #2e7d32;
}
.snackbar-info {
  background-color: #0288d1;
}

.modal-dark-theme {
  mat-dialog-container {
    color: white;
    background-color: #424951;
    h2 {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    .confirm-buttons {
      .btn-secondary {
        background-color: #fff0;
        color: #ffffff;
      }
    }
  }
}
