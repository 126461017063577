@import '~src/theme/theme-variables';

@mixin btn-continue(
  $height: 45px,
  $m-width: 200px,
  $font-size: 0.9rem,
  $py: 10px,
  $px: 20px,
  $bg: $red,
  $color: $white,
  $box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.5)
) {
  height: $height;
  font-size: $font-size;
  line-height: calc(#{$height} - #{$py} * 2);
  padding: $py $px;
  background-color: $bg;
  color: $color;
  border: none;
  box-shadow: $box-shadow;
  border-radius: $button-radius;
  min-width: $m-width;
  text-align: center;
  font-weight: 500;
  transition: background-color 200ms ease-in-out;

  &[disabled] {
    background-color: $gray-200;
    color: $gray-300;
  }

  &:focus {
    outline: none;
  }
}
