@import "~src/theme/theme-variables";


.mat-select {
  width: unset;
  flex: 1;
}


.g-app-select {
  .mat-select-trigger {
    color: $gray-700;
    text-align: center;
    border-bottom: 1px solid $gray-700;
    cursor: default;
  }

  .mat-select-arrow {
    color: $gray-700;
  }

  .mat-select-value {
    color: $gray-700;
  }

  .mat-select-placeholder {
    color: $gray-700;
  }

}

.g-app-panel {

  .mat-option {
    color: $gray-700;

    &.active {
      background: rgba(221, 221, 221, 0.4);
    }

    &.mat-option-head {
      text-align: center;
      pointer-events: none;
      background: $white;
      &.clickable {
        pointer-events: initial;
      }
    }
  }
}

