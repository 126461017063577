/*
 * Application global variables.
 */

// rgba(73, 73, 73, 0.4) = 494949
// rgba(170,170,170,0.4) = AAAAAA
// rgba(164, 164, 164, 0.4) = A4A4A4
// rgba(191,191,191,0.3) = BFBFBF - not counted
// fbfbfb - not counted
// c0c0c0 - not counted
// 757575 - not counted

$light-red: #f05545;
$red: #c01314;
$red-2: #b71c1c;

$green: #0bbf59;

$yellow: #ffbf00;

$gray-50: #c0c0c0;
$gray-100: #aaaaaa;
$gray-200: #dddddd;
$gray-300: #a4a4a4;
$gray-400: #8e9092;
$gray-500: #777777;
$gray-700: #424951;
$gray-900: #494949;
$gray-light: #bebebe;

$white: #fff;

// contrast colors
$dark-gray-contrast: $white;
$light-gray-contrast: $gray-700;

$red-contrast: $white;
$black-contrast: $white;

$button-radius: 2px;

// for borders
$charcoal-grey: #424951;
$light-gray-10: #f1f1f1;
$light-grey-20: #e0e0e0;
$light-grey-50: #a0a4a8;
