@import "~src/theme/theme-variables";
@import "~src/assets/mixins/btn-continue";

.footer-btn-container {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0px;
  height: 80px;
  background: $white;

  button {
    &.btn-primary,
    &.btn-secondary {
      min-width: 135px;
    }
  }
  button + button, button + a {
    margin-left: 20px;
  }

}
