.label-tab {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  color: $charcoal-grey;

  mat-icon {
    margin-right: 8px;
  }
}

.table.mat-table {
  width: 100%;

  th,
  td {
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
  }

  td {
    color: $charcoal-grey;
    cursor: pointer;
  }

  th {
    color: rgba(66, 73, 81, 0.6);
  }

  .alert {
    display: flex;
    align-items: flex-start;

    img {
      margin-right: 2px;
    }
  }
}

.table-wrap {
  max-height: calc(100vh - 140px);
  overflow: auto;
  width: 100%;

  &.modal-table {
    tr.mat-header-row {
      background-color: #dddddd;
      height: 43px;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;

      th {
        padding: 8px 0px;
        color: $charcoal-grey;

        &:first-of-type {
          border-top-left-radius: 7px;
          padding-left: 19px;
        }

        &:last-of-type {
          border-top-right-radius: 7px;
          padding-right: 19px;
        }
      }
    }
  }
}

.table-page {
  width: 100%;
  max-height: calc(100vh - 80px);
  overflow: hidden;

  .mat-tab-label {
    flex: 1;
    justify-content: flex-start;
    height: 60px;
  }

  .mat-ink-bar {
    height: 6px;
  }

  .mat-header-cell {
    font-size: 13px;
    font-weight: bold;
    color: rgba(66, 73, 81, 0.6);
  }

  .mat-cell,
  .mat-footer-cell {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $charcoal-grey;
  }

  .mat-footer-row,
  .mat-footer-cell,
  .mat-footer-cell:last-of-type,
  .mat-footer-cell:last-of-type {
    border-bottom: none;
    padding: 0px 15px;
  }
}
